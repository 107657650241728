import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import {
  Text,
  Icon,
  Flex,
  Title,
  Col,
  Row,
  Container,
  Box,
  Link,
} from '@firsttable/web-components/atoms';
import spaces from '@firsttable/web-theme/styledSpaces';
import { impressionEvent } from '@firsttable/functions';
import Restaurant from '../RestaurantOtherItem';

const Arrow = styled(Box)`
  outline: 0;
  color: ${themeGet('colors.brand.blue')};

  &:active {
    color: ${themeGet('colors.gold.900')};
  }
`;

export const Slider = styled.div`
  display: flex;
  overflow: auto;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &::after {
    content: '';
    display: block;
    width: 1px;
    flex-shrink: 0;
  }
  ${space};
`;

const SliderItem = styled(Box)`
  flex-shrink: 0;
`;

const RestaurantOther = ({
  restaurants,
  availabilitySession,
  region,
  city,
  sp,
  title,
  NavComponent,
  ...props
}) => {
  const [eventRestaurants, setRestaurants] = useState([]);
  const [eventTitle, setTitle] = useState('');
  const slider = useRef(null);
  const spaceBetween = 20;
  const restaurantWidth = 285;

  const go = (dir) => {
    const el = slider.current;
    const offset = restaurantWidth + spaceBetween;

    el.scrollTo({
      left: el.scrollLeft + (dir === 'back' ? -offset : offset),
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (eventRestaurants.length && eventTitle) {
      impressionEvent(eventTitle, eventRestaurants);
    }
  }, [eventRestaurants, eventTitle]);

  if (!region) {
    return null;
  }

  let currentRestaurants = restaurants.edges;
  let displayCity = false;
  // need to check if city is available. As this comp gets loaded on home as well.

  if (city) {
    const cityRestaurants = restaurants.edges.filter(
      (r) => r.node.cityId === city.foreignId,
    );
    displayCity = cityRestaurants.length > 2;
    currentRestaurants = displayCity ? cityRestaurants : restaurants.edges;
  }

  const slug = displayCity ? city.slug : region.slug;
  const cityTitle = displayCity ? city.menuTitle : region.menuTitle;
  const sliderTitle = title || `${cityTitle} Restaurants`;

  if (currentRestaurants.length && !eventRestaurants.length) {
    setRestaurants(currentRestaurants);
    setTitle(sliderTitle);
  }

  return (
    !!currentRestaurants.length && (
      <Box {...spaces(props)}>
        <Container>
          <Row>
            <Col>
              <Box
                textAlign={['center', null, 'left']}
                display={[null, null, 'flex']}
                alignItems="center"
                justifyContent="space-between"
                mb={56}
              >
                <Link NavComponent={NavComponent} to={slug} color="brand.blue">
                  <Title
                    display="inline"
                    css="&:hover {border-bottom: 2px solid;}"
                    mb={['l', null, 0]}
                    textAlign="center"
                    color={null}
                  >
                    {sliderTitle}
                  </Title>
                </Link>
                <Flex mt={[25, 35, 15]} justifyContent="center">
                  <Arrow
                    onClick={() => go('back')}
                    role="button"
                    aria-label="previous"
                  >
                    <Icon name="nav-arrow-left" color={null} />
                  </Arrow>
                  <Arrow
                    ml="m"
                    onClick={() => go('forward')}
                    role="button"
                    aria-label="next"
                  >
                    <Icon name="nav-arrow-right" color={null} />
                  </Arrow>
                </Flex>
              </Box>
            </Col>
          </Row>
        </Container>
        <Slider pl={sp} ref={slider}>
          {currentRestaurants.slice(0, 20).map(({ node }) => (
            <SliderItem
              key={node.id}
              width={restaurantWidth}
              mr={spaceBetween}
              mb={60}
            >
              <Restaurant
                NavComponent={NavComponent}
                restaurant={node}
                width={restaurantWidth}
                availabilitySession={
                  node.availabilitySessions.includes(availabilitySession)
                    ? availabilitySession
                    : node.availabilitySessions[0]
                }
                allowFiltering={false}
              />
            </SliderItem>
          ))}
          {currentRestaurants.length > 20 && (
            <SliderItem
              colors="card.s"
              width={restaurantWidth}
              mr={spaceBetween}
              mb={60}
            >
              <NavComponent
                to={city?.slug || region.slug}
                height="100%"
                display="flex"
              >
                <Box m="auto" textAlign="center" maxWidth="70%">
                  <Text
                    forwardedAs="span"
                    color="brand.blue"
                    css="border-bottom: 1px solid #26568245;"
                    m={0}
                  >
                    More {displayCity ? city.menuTitle : region.menuTitle}{' '}
                    restaurants
                  </Text>
                </Box>
              </NavComponent>
            </SliderItem>
          )}
        </Slider>
      </Box>
    )
  );
};

RestaurantOther.propTypes = {
  restaurants: PropTypes.shape(),
  region: PropTypes.shape(),
  city: PropTypes.shape(),
  sp: PropTypes.number,
  title: PropTypes.string,
  availabilitySession: PropTypes.string.isRequired,
  NavComponent: PropTypes.any.isRequired,
};

export default RestaurantOther;
