import React from 'react';
import PropTypes from 'prop-types';
import ListItemCard from '@firsttable/web-components/organisms/ListItemCard';
import withFirebase from '../../../hocs/withFireBaseAvailability';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const RestaurantOtherItem = ({ restaurant, ...props }) => {
  if (!restaurant.allImages) {
    return null;
  }
  const [img] = restaurant.allImages.edges.slice(0, 1);
  const subTitle =
    restaurant.cuisines && restaurant.cuisines.edges.length
      ? restaurant.cuisines.edges.map(({ node: { label } }) => label).join(', ')
      : null;
  return (
    <ListItemCard
      item={restaurant}
      img={{ ...img?.node }}
      subTitle={subTitle}
      {...props}
    />
  );
};

RestaurantOtherItem.propTypes = {
  restaurant: PropTypes.object,
  width: PropTypes.number,
  isLoading: PropTypes.bool,
  availability: PropTypes.shape(),
};

RestaurantOtherItem.defaultProps = {
  restaurant: {
    cuisines: {
      edges: [],
    },
  },
  width: 285,
  isLoading: true,
  availability: {},
};

export default withFirebase(RestaurantOtherItem);
