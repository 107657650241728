import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { isGlobalSite } from '../../helpers/data';
import SEO from '../../components/seo';
import PromoLayout from '../../layouts/PromoLayout';

const PromoPage = ({
  data: { allRegions, siteConfig, content, homeReviews },
}) => {
  if (isGlobalSite) {
    return <SEO meta={[{ 'http-equiv': 'refresh', content: '0;url=/' }]} />;
  }

  return (
    <PromoLayout
      regions={allRegions}
      siteConfig={siteConfig}
      content={content}
      homeReviews={homeReviews}
    />
  );
};

PromoPage.propTypes = {
  data: PropTypes.shape({
    allRegions: PropTypes.object,
    siteConfig: PropTypes.object,
    content: PropTypes.object,
    homeReviews: PropTypes.object,
  }).isRequired,
};

PromoPage.defaultProps = {};

export default PromoPage;

export const query = graphql`
  query allRegions {
    allRegions: allCity(filter: { parentId: { eq: 0 } }) {
      edges {
        node {
          id
          foreignId
          menuTitle
          slug
          regionId
          parentId
        }
      }
    }
    siteConfig {
      restaurantCount
      currency
      bookingPrice
    }
    content: allWhyContentJson {
      edges {
        node {
          title
          content
          id
        }
      }
    }
    homeReviews: allReview(limit: 7) {
      edges {
        node {
          id
          foreignId
          date
          comment
          name
          serviceRating
          foodRating
          userAvatar
          restaurant {
            title
          }
        }
      }
    }
  }
`;
